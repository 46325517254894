import React, {useCallback, useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {getHistory, getIncidents, getServiceNames} from "../request/statusRequests";
import {AppBar, Box, Grid, Toolbar, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import ServiceState from "./state/ServiceState";
import EventUpdates from "./events/EventUpdates";
import {map} from "async";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: "#eaeaea",
        height: "100vh",
    },

    main: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: '100%',
        width: "100vw",
        overflow: "auto",
    },
    appBar: {
        zIndex: 9999
    },
    menuButtonHidden: {
        display: 'none',
    },
    menuButton: {
        marginRight: 36,
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    toolbar: {
        paddingRight: 24,
    },
    toolbarDevelop: {
        paddingRight: 24,
        background: "#249d08"
    }
}));

const HomeScreen = () => {

    const version = process.env.REACT_APP_VERSION;

    const [services, setServices] = useState([]);
    const [incidents, setIncidents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updates, setUpdates] = useState(null);
    const [error, setError] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();


    const loadData = useCallback(async () => {

        try {
            setLoading(true);
            const serviceNames = await getServiceNames();
            setServices(serviceNames);

            const incidents = await getIncidents();
            setIncidents(incidents);

            if (incidents.length > 0) {
                const updates = await map(incidents, async incident => {
                    try {
                        return await getHistory(incident.id);
                    } catch (e) {
                        if (e?.response?.status === 503) return [];
                        throw e;
                    }
                });
                setUpdates(_.flatten(updates).map(item => {
                    return {...item, date: new Date(item.date)};
                }).sort((a, b) => b.date - a.date));
            }

        } catch (e) {
            enqueueSnackbar(t("error_loading"), {variant: "error"});
            setError(true);
        } finally {
            setLoading(false);
        }

    }, [enqueueSnackbar, t]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);


    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {t("title")}
                    </Typography>
                    <Typography variant={"subtitle2"}>{`${t("version")} ${version}`}</Typography>
                </Toolbar>
            </AppBar>
            <main className={classes.main}>
                <div className={classes.appBarSpacer}/>
                <div style={{display: "flex", maxWidth: 1048, marginLeft: "auto", marginRight: "auto", paddingTop: 32}}>
                    {error ? <Box display="flex" justifyContent="center" alignItems="center"
                                  style={{height: '100vh', width: "100%"}}>
                            <Typography variant="h4">{t("error_loading")}</Typography>
                        </Box> :
                        <Grid container alignItems="flex-start" style={{paddingLeft: 24, paddingRight: 24}}>
                            <ServiceState services={services} incidents={incidents} loading={loading}/>
                            {updates?.length > 0 &&
                                <EventUpdates services={services} updates={updates} incidents={incidents}
                                              loading={loading}/>}
                        </Grid>
                    }
                </div>
            </main>
        </div>
    );
};

export default HomeScreen;
