import {webClient} from "../config/axios";

export const getServiceNames = () => {
    return webClient.get("/service").then(response => {
        return response.data;
    });
};

export const getIncidents = () => {
    return webClient.get("/incident?status=open")
        .then(response => {
            return response.data;
        });
};

export const getHistory = (incidentId) => {
    return webClient.get(`/history/${incidentId}`)
        .then(response => {
            return response.data;
        });
};
