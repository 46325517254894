import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

const ServiceState = ({incidents, services, loading}) => {

    const {t} = useTranslation();

    const data = useMemo(() => {

        if (!incidents) return [];
        return services.map(service => {
            const serviceIncidents = incidents.find(incident => incident.service === service.id);
            return {
                service: service.name,
                incident: serviceIncidents !== undefined,
            };
        });
    }, [incidents, services]);


    return (
        <Card style={{minWidth:1000, padding:32}}>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <Typography style={{marginBottom: 24}} variant={"h5"}>{t("serviceState.title")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={200}/>
                    ) : (
                        <TableContainer component={Paper} style={{maxHeight: '500px', overflow: 'auto'}}>
                            <Table>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <TableRow key={index}
                                                  style={{ backgroundColor: item.incident ? "#ffeaea" : 'transparent' }}
                                        >
                                            <TableCell>
                                                <Typography variant="h6" >
                                                {item.service}
                                            </Typography>
                                            </TableCell>
                                            <TableCell align="right" style={{
                                                color: item.incident ? 'red' : 'green',
                                            }}>
                                                <Typography variant="h6">
                                                    {t(item.incident ? "serviceState.incidents" : "serviceState.noIncidents")}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};

export default ServiceState;
