import axios from 'axios';
import {CONNECTION_DEFAULT_TIMEOUT, CONNECTION_MAX_RETRIES, CONNECTION_RETRY_DELAY} from "../constants";

export const urlBase = process.env.REACT_APP_BACKEND_HOST ?? `api.${window.location.host}`;

export const webClient = axios.create({
    baseURL: `https://${urlBase}`,
    timeout: CONNECTION_DEFAULT_TIMEOUT,
    withCredentials: true
});

/*  This interceptor catches the 429 error (Too Many Requests) and retries the request after the delay specified in
    RETRY_DELAY. It will retry the request MAX_RETRIES times. */
webClient.interceptors.response.use(null, (error) => {
    if (!error.response || error.response.status !== 429) {
        throw error;
    }
    const currentRetries = error.config.__retry || 0;
    if (currentRetries >= CONNECTION_MAX_RETRIES) {
        throw error;
    }
    error.config.__retry = currentRetries + 1;
    const retryPromise = new Promise((resolve) => {
        setTimeout(resolve, CONNECTION_RETRY_DELAY);
    });
    return retryPromise.then(() => {
        return webClient(error.config);
    });
});
