import './App.css';
import {SnackbarProvider} from "notistack";
import * as Sentry from "@sentry/react";
import HomeScreen from "./components/HomeScreen";
import {ThemeProvider} from "@mui/material";
import './translate/i18n';
import {theme} from "./theme/theme";

function App() {
    return (
        <SnackbarProvider maxSnack={8}>
            <ThemeProvider theme={theme}>
                <Sentry.ErrorBoundary showDialog>
                    <HomeScreen/>
                </Sentry.ErrorBoundary>
            </ThemeProvider>
        </SnackbarProvider>);
}

export default App;
