import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {
    Card,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';


const columns = ["date", "service", "incident", "status", "detail"];

const EventUpdates = ({services, updates, incidents, loading}) => {

    const {t} = useTranslation();

    const data = useMemo(() => {
        return updates.map(update => {
            const incident = incidents.find(incident => incident.id === update.incidentId);
            const service = services.find(service => service.id === incident?.service);
            return {
                service: service.name,
                incident: incident?.name,
                date: update.date,
                status: update.type,
                detail: update.description
            };
        });
    }, [updates, incidents]);

    return (
        <Card style={{minWidth:1000, padding:32, marginTop:24}}>
        <Grid container item xs={12} >
            <Grid item xs={12}>
                <Typography style={{marginBottom: 24}} variant={"h5"}>{t("updates.title")}</Typography>
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={200}/>
                ) : (
                    <TableContainer component={Paper} style={{maxHeight: '500px', overflow: 'auto'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((item, index) =>
                                        <TableCell key={index}>
                                            <Typography variant="subtitle2" component="span" style={{ fontWeight: 'bold' }}>
                                                {t("updates.table.header." + item)}
                                            </Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((item, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {columns.map((field,index)=>
                                            <TableCell key={index}>{field === "date" ? item[field].toLocaleString() : field === "status" ? t(`updates.table.status.${item[field]}`): item[field]}</TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
        </Card>
    );
};

export default EventUpdates;
